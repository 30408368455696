@import '../../common/variables.css';

.container {
  margin: 84px auto;
  display: flex;
  flex-direction: row;

  @media (--max-sm-viewport) {
    margin: 32px auto;
    max-width: 328px;
    flex-direction: column;
  }
}

.image {
  width: 200px;
  aspect-ratio: 1;
  margin: 0 40px 0 auto;

  @media (--max-sm-viewport) {
    width: auto;
    margin: 0 auto;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: content-fit;
  }
}

.content {
  margin-right: auto;
  @media (--max-sm-viewport) {
    margin-right: 0;
  }
}

.title {
  margin-top: 36px;
  @media (--max-sm-viewport) {
    margin-top: 8px;
  }
}

.description {
  margin-top: 16px;
}

.buttons {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  @media (--max-md-viewport) {
    flex-direction: column;
  }
}

.primaryButton {
  order: 2;
  min-width: 280px;
  @media (--max-md-viewport) {
    order: initial;
    min-width: none;
  }
}
.tertiaryButton {
  order: 1;
  margin: 0 80px 0 0;
  @media (--max-md-viewport) {
    order: initial;
    margin: 16px 0 0;
  }
}
